<template>
    <div class="home">
        <location :locationArr="locationArr"/>
        <div class="particularsBox">
            <h3>{{resData.newsTitle}}</h3>
            <p>发布时间:{{resData.infoStartTime}}</p>
            <div class="wire"></div>
            <div class="particularsCon" v-html="resData.newsContent"></div>
        </div>
    </div>
</template>

<script>
    import location from '@/components/location.vue'

    export default {
        name: 'shgkBzryDetail',
        components: {
            location
        },
        data() {
            return {
                resData: {},
                page: 0,
                locationArr: [{title: '商会概况', url: '/shgk'}, {title: '表彰荣誉', url: '/shgk?typeId=bzry'}]
            }
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile/shgk/bzry/detail",
						query:{
							id:this.$route.query.id
						}
			        });
			}
		},
        mounted() {
            this.getInfoData()
        },
        methods: {
            getInfoData() {
                if (this.$route.path != '/shgk/bzry/detail') {
                    return;
                }
                const id = this.$route.query.id
                if (typeof (id) == 'undefined' || id == '') {
                    return;
                }
                this.$http.shgkShbzryDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            }
        },
        watch: {
            $route: 'getInfoData'
        }
    }
</script>
<style type="text/css">
    @import '~@/assets/css/Par.css';
</style>
